export const VIEWS_FOLDER = {
  GENERAL: 'general',
  WILLS: 'wills',
  PROBATE: 'probate',
  CREMATION: 'cremation',
  PREVIEWS: 'previews',
}

export const VIEWS_FOLDER_KEYS = {
  [VIEWS_FOLDER.GENERAL]: 'GENERAL',
  [VIEWS_FOLDER.WILLS]: 'WILLS',
  [VIEWS_FOLDER.PROBATE]: 'PROBATE',
  [VIEWS_FOLDER.CREMATION]: 'CREMATION',
  [VIEWS_FOLDER.PREVIEWS]: 'PREVIEWS',
}

export enum PAGE_TYPE {
  PUBLIC = 'public',
  PPC = 'ppc',
  TEMPLATE = 'template',
}

export const PAGE_TYPE_KEYS = {
  [PAGE_TYPE.PUBLIC]: 'PUBLIC',
  [PAGE_TYPE.PPC]: 'PPC',
  [PAGE_TYPE.TEMPLATE]: 'TEMPLATE',
}
