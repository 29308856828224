import * as Sentry from '@sentry/gatsby'
import Cookies from 'js-cookie'
import { COOKIE_BANNER_ENABLED } from 'config'
import { hasUserAgreedToCookie, isOptionalCookie } from './cookieYes'
import { CookieNames } from './constants'

export const getCookie = (name: string): string | undefined => Cookies.get(name)

export const setCookie = (
  name: string,
  value: string | Record<string, unknown>,
  attributes?: Cookies.CookieAttributes
): string | undefined => {
  if (COOKIE_BANNER_ENABLED) {
    if (!Object.values(CookieNames).includes(name)) {
      // Log (but don't throw) the exception to Sentry so we are made aware of the
      // missing cookie configuration but without making the app unusable.
      const error = new Error(`Cookie ${name} does not exist in CookieNames`)
      // Temporary logging to assist with testing
      console.warn(`[Cookies] Cookie ${name} does not exist in CookieNames`)
      Sentry.captureException(error)
    }
    if (isOptionalCookie(name) && !hasUserAgreedToCookie(name)) return
  }
  Cookies.set(name, value, attributes)
}

export const removeCookie = (name: string): void => {
  Cookies.remove(name)
}

export const getParsedCookie = <T>(name: string): T | null => {
  try {
    const cookieString = getCookie(name)
    return cookieString ? JSON.parse(cookieString) : null
  } catch {
    return null
  }
}

export const getAllCookies = (): Record<string, string> => Cookies.get()
