import { createContext } from 'react'
import { PRODUCTS } from 'lib/products/constants'

interface ContextProps {
  loadedTracking: boolean
  trackedPhoneNumber: string
  product?: PRODUCTS
}

const PageContext = createContext<ContextProps>({
  loadedTracking: false,
  trackedPhoneNumber: '',
})

export default PageContext
