import { CookieAttributes } from 'js-cookie'
import { TRACKING_COOKIE_DOMAIN } from 'config'
import { CookieNames } from 'lib/storage/cookies/constants'
import { getParsedCookie, setCookie } from 'lib/storage/cookies/helpers'

import {
  AttributedTrackingParameters,
  TrackingParameters,
  WillsReferralParameters,
} from './interfaces'

import {
  ATTRIBUTION_TYPES,
  COOKIE_NAME_BY_ATTRIBUTION_TYPE,
  TRACKING_TYPES,
} from './constants'

import {
  gatherTrackingParams,
  gatherTrackingParamsAsync,
} from './trackingParamsHelpers'

const { LAST_TOUCH_TRACKING_COOKIE_NAME, WILLS_REFERRAL_PARAMS_COOKIE_NAME } =
  CookieNames

export const getSavedTrackingParams = (): AttributedTrackingParameters[] => {
  const attributionTypes = [
    ATTRIBUTION_TYPES.FIRST_TOUCH,
    ATTRIBUTION_TYPES.LAST_TOUCH,
  ]
  return attributionTypes
    .map((attribution) => {
      const parameters = getParsedCookie<TrackingParameters>(
        COOKIE_NAME_BY_ATTRIBUTION_TYPE[attribution]
      ) as TrackingParameters
      return { attribution, ...parameters }
    })
    .filter((trackingParameters) => trackingParameters.utmSource)
}

/**
 * Helper for composing the attributes object for a cookie. Handles
 * conditionally setting the `domain` if the environment requires it (in Mirror
 * and Prod this is set to farewill.com, but otherwise should not be set).
 *
 * Exported from this module to allow testing. Not intended for use outside this
 * module for cookies which are not tracking-related (as the domain is
 * conditionally set based on `TRACKING_COOKIE_DOMAIN`).
 *
 * @param expiresAt Cookie expiration in days
 * @returns A partial CookieAttributes object containing `expires` and
 *  (optionally) `domain` attributes.
 */
export const getCookieAttributes = (expiresAt = 90): CookieAttributes => ({
  expires: expiresAt,
  ...(TRACKING_COOKIE_DOMAIN ? { domain: TRACKING_COOKIE_DOMAIN } : null),
})

/** If the partnerId wasn't set, we will save it as null in the WillsReferralParams */
const getReferringPartnerId = (partnerId: string): string | null => {
  if (partnerId !== TRACKING_TYPES.NOT_SET) {
    return partnerId
  }
  return null
}
/**
 * Store referral parameters required for the correct function of free charity
 * wills. This data is stored in a separate cookie from the tracking cookie so
 * that users can still receive free charity wills even if they block the
 * tracking cookie.
 */
export const saveWillsReferralParams = (
  { branding, referrer, referringPartnerId, voucher }: WillsReferralParameters,
  expiresAt = 90
): void => {
  const attributes = getCookieAttributes(expiresAt)
  setCookie(
    WILLS_REFERRAL_PARAMS_COOKIE_NAME,
    {
      branding: branding || null,
      referrer,
      referringPartnerId: getReferringPartnerId(referringPartnerId),
      voucher,
    },
    attributes
  )
}

export const saveTrackingParams = (
  partnerPageUtms = {},
  cookieName = LAST_TOUCH_TRACKING_COOKIE_NAME,
  expiresAt = 90
): TrackingParameters => {
  const params = gatherTrackingParams(partnerPageUtms)
  const attributes = getCookieAttributes(expiresAt)
  setCookie(cookieName, params, attributes)
  return params
}

export const saveTrackingParamsAsync = async (
  cookieName = LAST_TOUCH_TRACKING_COOKIE_NAME,
  expiresAt = 90
): Promise<Partial<TrackingParameters>> => {
  // get current params from cookie
  const currentParams = getParsedCookie<TrackingParameters | null>(cookieName)

  // if params not set in cookie
  // stop as we do not want to set cookie without these values
  if (!currentParams) return {}

  const asyncParams = await gatherTrackingParamsAsync()

  // if async params to be set are undefined then return current params
  if (!asyncParams.snowplowId && !asyncParams.optimiseExp) return currentParams

  // if async params are the same as the sync params already set then return current params
  if (
    currentParams.snowplowId === asyncParams.snowplowId &&
    currentParams.optimiseExp === asyncParams.optimiseExp
  )
    return currentParams

  // if async params are defined set updated params with async param values
  // when the current param value is the default value i.e. hasn't been set
  const updatedParams = {
    ...currentParams,
  }

  if (
    currentParams.snowplowId === TRACKING_TYPES.NONE &&
    asyncParams.snowplowId &&
    asyncParams.snowplowId !== TRACKING_TYPES.NONE
  ) {
    updatedParams.snowplowId = asyncParams.snowplowId
  }

  if (
    currentParams.optimiseExp === TRACKING_TYPES.NONE &&
    asyncParams.optimiseExp &&
    asyncParams.optimiseExp !== TRACKING_TYPES.NONE
  ) {
    updatedParams.optimiseExp = asyncParams.optimiseExp
  }
  // set cookie with updated params
  setCookie(cookieName, updatedParams, getCookieAttributes(expiresAt))

  return updatedParams
}
