import { getPathsObject } from 'lib/routing/paths'
import PAGES from '../../pages'

const PATHS = getPathsObject(PAGES)

/**
 * Returns a nested object of page paths organised by VIEWS_FOLDER and page KEY
 * Non-public pages are additionally namespaced under their PAGE_TYPE
 *
 * import PATHS from 'paths'
 *
 * PATHS.GENERAL.HOME
 * PATHS.PROBATE.OVERVIEW
 * PATHS.PROBATE.PPC.LAWYERS
 */

export default PATHS

/**
 * Should be kept in sync with client_app_prefixes
 * in config/nginx.conf.erb
 * see README for more details
 */
export const CLIENT_PATHS = []
