import React from 'react'

import PageContext from 'PageContext'
import { useCallRailTracking } from 'lib/tracking/useCallRailTracking'
import { PRODUCTS } from 'lib/products/constants'

export interface PageProviderProps {
  children: React.ReactNode
  pageContext: {
    pageType: string
    product?: PRODUCTS
  }
}

/**
 * This component wraps every page, providing PageContext all components
 * It's used by gatsby-plugin-layout, configured in lib/gatsby/config
 */
const PageProvider = ({
  children,
  pageContext: { pageType, product },
}: PageProviderProps): JSX.Element => {
  const { loadedTracking, trackedPhoneNumber } = useCallRailTracking()

  const values = {
    pageType,
    product,
    loadedTracking,
    trackedPhoneNumber,
  }

  return <PageContext.Provider value={values}>{children}</PageContext.Provider>
}

export default PageProvider
