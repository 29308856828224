import { getCookie } from './helpers'
import { CookieCategories } from './constants'

type CookieCategoryNames =
  | 'necessary'
  | 'functional'
  | 'analytics'
  | 'performance'
  | 'advertisement'

/**
 * [1] In our current configuration this will never be returned because we hide the category in our CookieYes configuration
 *     https://www.notion.so/farewill/Cookies-1cab4b51d61e4999b9fa5cb06fd7545d?pvs=4#224731af6bd243ef897026b64eb8e7ad
 *
 * We believe there is a bug in the CookieYes library, where the cookie is initialised with empty string values for a few miliseconds,
 * before being updated with the correct values. We have filed a support ticket with CookieYes to investigate this further: number 17596.
 */
type CookieYesConsentCookieValue = {
  consentid: string
  consent: 'yes' | 'no' | ''
  action: 'yes' | 'no' | ''
  necessary: 'yes' | 'no' | ''
  functional?: 'yes' | 'no' | ''
  analytics?: 'yes' | 'no' | ''
  advertisement?: 'yes' | 'no' | ''
  performance?: 'yes' | 'no' | '' // [1]
} | null

export const getCookieConsent = (): CookieYesConsentCookieValue => {
  const cookie = getCookie('cookieyes-consent')
  if (!cookie) {
    return null
  }

  const result: Record<string, string> = cookie
    .split(',')
    .reduce((acc, pair) => {
      const [key, value] = pair.split(':')
      acc[key] = value
      return acc
    }, {} as Record<string, string>)

  return result as CookieYesConsentCookieValue
}

const getCookieMap = (
  cookiesByCategory: typeof CookieCategories
): Map<string, CookieCategoryNames> => {
  const map = new Map()
  Object.entries(cookiesByCategory).forEach(([category, cookies]) => {
    cookies.forEach((cookie) => {
      map.set(cookie, category)
    })
  })
  return map
}

const getCategoryForCookie = (
  cookieName: string
): CookieCategoryNames | null => {
  const cookieMap = getCookieMap(CookieCategories)
  return cookieMap.get(cookieName) || null
}

export const userGrantedConsentForCategory = (
  category: CookieCategoryNames
): boolean => {
  const consentMap = getCookieConsent()
  if (!consentMap) {
    return false
  }
  return consentMap[category] === 'yes'
}

export const hasUserAgreedToCookie = (name: string): boolean => {
  const cookieCategory = getCategoryForCookie(name)
  if (!cookieCategory) {
    return false
  }
  const consented = userGrantedConsentForCategory(cookieCategory)
  return consented
}

export const isOptionalCookie = (name: string): boolean =>
  !CookieCategories.necessary.includes(name)

export const exportedForTesting = {
  getCookieMap,
  getCategoryForCookie,
}
