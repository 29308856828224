import qs from 'qs'

export const getQueryParameters = (): qs.ParsedQs | Record<string, never> => {
  if (typeof window === 'undefined' || !window.location.search) {
    return {}
  }

  // This removes any trailing whitespace on tracking values (and other query parameters!) which prevents matching errors when trackings are generated with a trailing whitespace due to user error.
  const parseOptions: qs.IParseOptions = {
    ignoreQueryPrefix: true,
    decoder: (input, defaultDecoder) => {
      const output = defaultDecoder(input)
      return typeof output === 'string' ? output.trim() : output
    },
  }
  return qs.parse(window.location.search, parseOptions) as qs.ParsedQs
}
