const PUBLIC = {
  OVERVIEW: {
    PATH: '/apply-for-probate',
    VIEW: 'Apply',
  },
  ESTATE_ADMIN: {
    PATH: '/probate/complete-probate',
    VIEW: 'EstateAdministration',
  },
}

const PPC = {
  OVERVIEW: {
    PATH: '/landing/apply-for-probate',
    VIEW: 'Apply',
  },
  SOLICITORS_NEW: {
    PATH: '/landing/probate-solicitors',
    VIEW: 'SolicitorsNew',
  },
  SOLICITORS_QUOTE: {
    PATH: '/landing/probate-solicitors-quote',
    VIEW: 'SolicitorsQuote',
  },
  REQUIRED: {
    PATH: '/landing/probate-required',
    VIEW: 'Required',
  },
}

export const ROUTES = { PUBLIC, PPC }
