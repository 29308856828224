/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from 'react'
import { globalHistory, WindowLocation } from '@reach/router'
import * as Sentry from '@sentry/gatsby'

import { trackPageChange } from '../tracking'

export const onRouteUpdate = ({
  location,
}: {
  location: WindowLocation
}): void => {
  trackPageChange(location.pathname)
}

export const onInitialClientRender = (): void => {
  /**
   * This is a workaround for a bug in Gatsby
   *
   * See https://github.com/gatsbyjs/gatsby/issues/8357 for more details
   */
  /**
   * The _onTransitionComplete method doesn't have any types
   * so we can use this expect error flag here until we update gatsby
   *
   * @ts-expect-error */
  // eslint-disable-next-line no-underscore-dangle
  globalHistory._onTransitionComplete()
}

export const wrapRootElement = ({
  element,
}: {
  element: React.ReactNode
}): React.ReactElement => <Sentry.ErrorBoundary>{element}</Sentry.ErrorBoundary>
