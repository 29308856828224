import { storageAvailable } from './availability'

export const getLocalStorage = <T>(key: string): T | null => {
  if (storageAvailable('localStorage')) {
    const item = localStorage.getItem(key)
    return item ? JSON.parse(item) : null
  }
  return null
}

export const setLocalStorage = <T>(key: string, data: T): void | null => {
  if (storageAvailable('localStorage')) {
    const existingStoreData = getLocalStorage<T>(key)
    return localStorage.setItem(
      key,
      JSON.stringify({ ...existingStoreData, ...data })
    )
  }
  return null
}
