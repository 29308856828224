const PUBLIC = {
  ARTICLE_PREVIEW: {
    PATH: '/previews/article',
    VIEW: 'Article',
  },
  BLOG_PREVIEW: {
    PATH: '/previews/blog',
    VIEW: 'Blog',
  },
  LFP_PREVIEW: {
    PATH: '/previews/funeral',
    VIEW: 'LocalFuneralPages',
  },
}

export const ROUTES = { PUBLIC }
