// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-views-cremation-ppc-direct-experiment-index-tsx": () => import("./../../../src/views/cremation/ppc/DirectExperiment/index.tsx" /* webpackChunkName: "component---src-views-cremation-ppc-direct-experiment-index-tsx" */),
  "component---src-views-cremation-ppc-funeral-overview-index-tsx": () => import("./../../../src/views/cremation/ppc/FuneralOverview/index.tsx" /* webpackChunkName: "component---src-views-cremation-ppc-funeral-overview-index-tsx" */),
  "component---src-views-cremation-ppc-funeral-plans-index-tsx": () => import("./../../../src/views/cremation/ppc/FuneralPlans/index.tsx" /* webpackChunkName: "component---src-views-cremation-ppc-funeral-plans-index-tsx" */),
  "component---src-views-cremation-ppc-simple-core-local-index-tsx": () => import("./../../../src/views/cremation/ppc/SimpleCoreLocal/index.tsx" /* webpackChunkName: "component---src-views-cremation-ppc-simple-core-local-index-tsx" */),
  "component---src-views-cremation-public-commission-disclosure-instalments-index-tsx": () => import("./../../../src/views/cremation/public/CommissionDisclosureInstalments/index.tsx" /* webpackChunkName: "component---src-views-cremation-public-commission-disclosure-instalments-index-tsx" */),
  "component---src-views-cremation-public-commission-disclosure-upfront-index-tsx": () => import("./../../../src/views/cremation/public/CommissionDisclosureUpfront/index.tsx" /* webpackChunkName: "component---src-views-cremation-public-commission-disclosure-upfront-index-tsx" */),
  "component---src-views-cremation-public-compare-index-tsx": () => import("./../../../src/views/cremation/public/Compare/index.tsx" /* webpackChunkName: "component---src-views-cremation-public-compare-index-tsx" */),
  "component---src-views-cremation-public-direct-index-tsx": () => import("./../../../src/views/cremation/public/Direct/index.tsx" /* webpackChunkName: "component---src-views-cremation-public-direct-index-tsx" */),
  "component---src-views-cremation-public-funeral-overview-index-tsx": () => import("./../../../src/views/cremation/public/FuneralOverview/index.tsx" /* webpackChunkName: "component---src-views-cremation-public-funeral-overview-index-tsx" */),
  "component---src-views-cremation-public-funeral-plans-index-tsx": () => import("./../../../src/views/cremation/public/FuneralPlans/index.tsx" /* webpackChunkName: "component---src-views-cremation-public-funeral-plans-index-tsx" */),
  "component---src-views-cremation-public-get-a-guide-callback-index-tsx": () => import("./../../../src/views/cremation/public/GetAGuideCallback/index.tsx" /* webpackChunkName: "component---src-views-cremation-public-get-a-guide-callback-index-tsx" */),
  "component---src-views-cremation-public-get-a-guide-form-index-tsx": () => import("./../../../src/views/cremation/public/GetAGuideForm/index.tsx" /* webpackChunkName: "component---src-views-cremation-public-get-a-guide-form-index-tsx" */),
  "component---src-views-cremation-public-get-a-guide-index-tsx": () => import("./../../../src/views/cremation/public/GetAGuide/index.tsx" /* webpackChunkName: "component---src-views-cremation-public-get-a-guide-index-tsx" */),
  "component---src-views-cremation-public-terms-index-tsx": () => import("./../../../src/views/cremation/public/Terms/index.tsx" /* webpackChunkName: "component---src-views-cremation-public-terms-index-tsx" */),
  "component---src-views-cremation-templates-partner-landing-page-index-tsx": () => import("./../../../src/views/cremation/templates/PartnerLandingPage/index.tsx" /* webpackChunkName: "component---src-views-cremation-templates-partner-landing-page-index-tsx" */),
  "component---src-views-general-public-about-index-tsx": () => import("./../../../src/views/general/public/About/index.tsx" /* webpackChunkName: "component---src-views-general-public-about-index-tsx" */),
  "component---src-views-general-public-article-list-articles-index-js": () => import("./../../../src/views/general/public/ArticleList/Articles/index.js" /* webpackChunkName: "component---src-views-general-public-article-list-articles-index-js" */),
  "component---src-views-general-public-article-list-blog-index-js": () => import("./../../../src/views/general/public/ArticleList/Blog/index.js" /* webpackChunkName: "component---src-views-general-public-article-list-blog-index-js" */),
  "component---src-views-general-public-author-list-index-js": () => import("./../../../src/views/general/public/AuthorList/index.js" /* webpackChunkName: "component---src-views-general-public-author-list-index-js" */),
  "component---src-views-general-public-careers-index-tsx": () => import("./../../../src/views/general/public/Careers/index.tsx" /* webpackChunkName: "component---src-views-general-public-careers-index-tsx" */),
  "component---src-views-general-public-charities-index-tsx": () => import("./../../../src/views/general/public/Charities/index.tsx" /* webpackChunkName: "component---src-views-general-public-charities-index-tsx" */),
  "component---src-views-general-public-contact-index-js": () => import("./../../../src/views/general/public/Contact/index.js" /* webpackChunkName: "component---src-views-general-public-contact-index-js" */),
  "component---src-views-general-public-content-hub-index-tsx": () => import("./../../../src/views/general/public/ContentHub/index.tsx" /* webpackChunkName: "component---src-views-general-public-content-hub-index-tsx" */),
  "component---src-views-general-public-content-hub-search-page-index-tsx": () => import("./../../../src/views/general/public/ContentHub/SearchPage/index.tsx" /* webpackChunkName: "component---src-views-general-public-content-hub-search-page-index-tsx" */),
  "component---src-views-general-public-home-index-tsx": () => import("./../../../src/views/general/public/Home/index.tsx" /* webpackChunkName: "component---src-views-general-public-home-index-tsx" */),
  "component---src-views-general-public-john-booth-index-js": () => import("./../../../src/views/general/public/JohnBooth/index.js" /* webpackChunkName: "component---src-views-general-public-john-booth-index-js" */),
  "component---src-views-general-public-not-found-index-js": () => import("./../../../src/views/general/public/NotFound/index.js" /* webpackChunkName: "component---src-views-general-public-not-found-index-js" */),
  "component---src-views-general-public-policy-index-tsx": () => import("./../../../src/views/general/public/Policy/index.tsx" /* webpackChunkName: "component---src-views-general-public-policy-index-tsx" */),
  "component---src-views-general-public-press-index-js": () => import("./../../../src/views/general/public/Press/index.js" /* webpackChunkName: "component---src-views-general-public-press-index-js" */),
  "component---src-views-general-public-security-index-js": () => import("./../../../src/views/general/public/Security/index.js" /* webpackChunkName: "component---src-views-general-public-security-index-js" */),
  "component---src-views-general-public-security-reporting-index-js": () => import("./../../../src/views/general/public/SecurityReporting/index.js" /* webpackChunkName: "component---src-views-general-public-security-reporting-index-js" */),
  "component---src-views-general-public-supported-browsers-index-tsx": () => import("./../../../src/views/general/public/SupportedBrowsers/index.tsx" /* webpackChunkName: "component---src-views-general-public-supported-browsers-index-tsx" */),
  "component---src-views-general-public-what-to-do-when-someone-dies-index-js": () => import("./../../../src/views/general/public/WhatToDoWhenSomeoneDies/index.js" /* webpackChunkName: "component---src-views-general-public-what-to-do-when-someone-dies-index-js" */),
  "component---src-views-general-templates-article-category-group-index-tsx": () => import("./../../../src/views/general/templates/ArticleCategoryGroup/index.tsx" /* webpackChunkName: "component---src-views-general-templates-article-category-group-index-tsx" */),
  "component---src-views-general-templates-article-category-index-tsx": () => import("./../../../src/views/general/templates/ArticleCategory/index.tsx" /* webpackChunkName: "component---src-views-general-templates-article-category-index-tsx" */),
  "component---src-views-general-templates-author-index-js": () => import("./../../../src/views/general/templates/Author/index.js" /* webpackChunkName: "component---src-views-general-templates-author-index-js" */),
  "component---src-views-general-templates-blog-article-index-js": () => import("./../../../src/views/general/templates/BlogArticle/index.js" /* webpackChunkName: "component---src-views-general-templates-blog-article-index-js" */),
  "component---src-views-general-templates-guide-index-js": () => import("./../../../src/views/general/templates/Guide/index.js" /* webpackChunkName: "component---src-views-general-templates-guide-index-js" */),
  "component---src-views-general-templates-help-article-index-js": () => import("./../../../src/views/general/templates/HelpArticle/index.js" /* webpackChunkName: "component---src-views-general-templates-help-article-index-js" */),
  "component---src-views-general-templates-local-funeral-page-ppc-index-tsx": () => import("./../../../src/views/general/templates/LocalFuneralPage/ppc/index.tsx" /* webpackChunkName: "component---src-views-general-templates-local-funeral-page-ppc-index-tsx" */),
  "component---src-views-general-templates-local-funeral-page-public-index-js": () => import("./../../../src/views/general/templates/LocalFuneralPage/public/index.js" /* webpackChunkName: "component---src-views-general-templates-local-funeral-page-public-index-js" */),
  "component---src-views-general-templates-partner-terms-index-js": () => import("./../../../src/views/general/templates/PartnerTerms/index.js" /* webpackChunkName: "component---src-views-general-templates-partner-terms-index-js" */),
  "component---src-views-previews-public-article-index-js": () => import("./../../../src/views/previews/public/Article/index.js" /* webpackChunkName: "component---src-views-previews-public-article-index-js" */),
  "component---src-views-previews-public-blog-index-js": () => import("./../../../src/views/previews/public/Blog/index.js" /* webpackChunkName: "component---src-views-previews-public-blog-index-js" */),
  "component---src-views-previews-public-local-funeral-pages-index-js": () => import("./../../../src/views/previews/public/LocalFuneralPages/index.js" /* webpackChunkName: "component---src-views-previews-public-local-funeral-pages-index-js" */),
  "component---src-views-probate-ppc-apply-index-tsx": () => import("./../../../src/views/probate/ppc/Apply/index.tsx" /* webpackChunkName: "component---src-views-probate-ppc-apply-index-tsx" */),
  "component---src-views-probate-ppc-required-index-tsx": () => import("./../../../src/views/probate/ppc/Required/index.tsx" /* webpackChunkName: "component---src-views-probate-ppc-required-index-tsx" */),
  "component---src-views-probate-ppc-solicitors-new-index-tsx": () => import("./../../../src/views/probate/ppc/SolicitorsNew/index.tsx" /* webpackChunkName: "component---src-views-probate-ppc-solicitors-new-index-tsx" */),
  "component---src-views-probate-ppc-solicitors-quote-index-tsx": () => import("./../../../src/views/probate/ppc/SolicitorsQuote/index.tsx" /* webpackChunkName: "component---src-views-probate-ppc-solicitors-quote-index-tsx" */),
  "component---src-views-probate-public-apply-index-tsx": () => import("./../../../src/views/probate/public/Apply/index.tsx" /* webpackChunkName: "component---src-views-probate-public-apply-index-tsx" */),
  "component---src-views-probate-public-estate-administration-index-js": () => import("./../../../src/views/probate/public/EstateAdministration/index.js" /* webpackChunkName: "component---src-views-probate-public-estate-administration-index-js" */),
  "component---src-views-probate-templates-partner-landing-page-index-tsx": () => import("./../../../src/views/probate/templates/PartnerLandingPage/index.tsx" /* webpackChunkName: "component---src-views-probate-templates-partner-landing-page-index-tsx" */),
  "component---src-views-wills-ppc-solicitors-index-tsx": () => import("./../../../src/views/wills/ppc/Solicitors/index.tsx" /* webpackChunkName: "component---src-views-wills-ppc-solicitors-index-tsx" */),
  "component---src-views-wills-ppc-trusts-index-tsx": () => import("./../../../src/views/wills/ppc/Trusts/index.tsx" /* webpackChunkName: "component---src-views-wills-ppc-trusts-index-tsx" */),
  "component---src-views-wills-public-for-couples-index-js": () => import("./../../../src/views/wills/public/ForCouples/index.js" /* webpackChunkName: "component---src-views-wills-public-for-couples-index-js" */),
  "component---src-views-wills-public-overview-index-tsx": () => import("./../../../src/views/wills/public/Overview/index.tsx" /* webpackChunkName: "component---src-views-wills-public-overview-index-tsx" */),
  "component---src-views-wills-public-pricing-index-js": () => import("./../../../src/views/wills/public/Pricing/index.js" /* webpackChunkName: "component---src-views-wills-public-pricing-index-js" */),
  "component---src-views-wills-public-referral-index-js": () => import("./../../../src/views/wills/public/Referral/index.js" /* webpackChunkName: "component---src-views-wills-public-referral-index-js" */),
  "component---src-views-wills-public-telephone-wills-index-tsx": () => import("./../../../src/views/wills/public/TelephoneWills/index.tsx" /* webpackChunkName: "component---src-views-wills-public-telephone-wills-index-tsx" */),
  "component---src-views-wills-public-terms-index-tsx": () => import("./../../../src/views/wills/public/Terms/index.tsx" /* webpackChunkName: "component---src-views-wills-public-terms-index-tsx" */),
  "component---src-views-wills-templates-grey-label-partner-landing-page-macmillan-index-tsx": () => import("./../../../src/views/wills/templates/GreyLabelPartnerLandingPage/Macmillan/index.tsx" /* webpackChunkName: "component---src-views-wills-templates-grey-label-partner-landing-page-macmillan-index-tsx" */),
  "component---src-views-wills-templates-partner-online-and-telephone-index-tsx": () => import("./../../../src/views/wills/templates/PartnerOnlineAndTelephone/index.tsx" /* webpackChunkName: "component---src-views-wills-templates-partner-online-and-telephone-index-tsx" */),
  "component---src-views-wills-templates-partner-online-index-tsx": () => import("./../../../src/views/wills/templates/PartnerOnline/index.tsx" /* webpackChunkName: "component---src-views-wills-templates-partner-online-index-tsx" */),
  "component---src-views-wills-templates-partner-telephone-index-tsx": () => import("./../../../src/views/wills/templates/PartnerTelephone/index.tsx" /* webpackChunkName: "component---src-views-wills-templates-partner-telephone-index-tsx" */)
}

