import { getLocalStorage, setLocalStorage } from 'lib/storage/localStorage'
import { GoogleClickId } from './interfaces'

const gclidIsValid = (gclid: GoogleClickId): boolean =>
  gclid && new Date().getTime() < gclid.expiryDate

export const getGoogleClickId = (): GoogleClickId | undefined => {
  const gclid = getLocalStorage('gclid') as GoogleClickId
  return gclidIsValid(gclid) ? gclid : undefined
}

// A google click source is valid if the gclsrc is empty or contains 'aw'
// https://support.google.com/searchads/answer/7342044
const srcIsValid = (gclsrc: string) => !gclsrc || gclsrc.indexOf('aw') !== -1

const getExpiryRecord = (value: string) => {
  const expiryPeriod = 90 * 24 * 60 * 60 * 1000
  const expiryDate = new Date().getTime() + expiryPeriod
  return {
    value,
    expiryDate,
  }
}

export const storeGoogleClickId = ({
  gclid,
  gclsrc,
}: {
  gclid: string
  gclsrc: string
}): void => {
  const isGclsrcValid = srcIsValid(gclsrc)

  if (gclid && isGclsrcValid) {
    const gclidWithExpiry = getExpiryRecord(gclid)
    setLocalStorage('gclid', gclidWithExpiry)
  }
}
