import { useContext, useEffect } from 'react'
import { SplitContext } from '@splitsoftware/splitio-react'

import { getUserConsent } from 'lib/split/helpers'

/**
 * CookieYes fires a `cookieyes_consent_update` event on the document whenever
 * the user updates their consent preferences. When this happens we inform Split
 * about the change in consent so that its consent mode is updated.
 *
 * See https://www.cookieyes.com/documentation/events-on-cookie-banner-interactions/
 */
export const useSplitCookieYesConsent = (): void => {
  const { factory } = useContext(SplitContext)

  const setConsent = () => {
    const userConsentValue = getUserConsent()
    if (userConsentValue !== 'UNKNOWN') {
      factory?.UserConsent.setStatus(userConsentValue === 'GRANTED')
    }
  }

  useEffect(() => {
    document.addEventListener('cookieyes_consent_update', setConsent)
    return () => {
      document.removeEventListener('cookieyes_consent_update', setConsent)
    }
  }, [])
}
