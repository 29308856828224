import { VIEWS_FOLDER, PAGE_TYPE } from './constants'
import { Pages, PageTypeKey, ViewsFolder } from './types'

type Views = Record<
  string,
  {
    [T in PageTypeKey]?: Record<string, { PATH: string; VIEW: string }>
  }
>

export const getPagesArray = (viewsFolderMap: Views): Pages[] => {
  const pages: Pages[] = []

  Object.entries(viewsFolderMap).forEach(([viewsFolder, folderRoutes]) => {
    if (!Object.values(VIEWS_FOLDER).includes(viewsFolder))
      throw new Error(`No VIEWS_FOLDER '${viewsFolder}'`)

    Object.entries(folderRoutes).forEach(([pageTypeKey, pagesMap]) => {
      const pageType = PAGE_TYPE[pageTypeKey as PageTypeKey]

      if (!pageType)
        throw new Error(
          `No PAGE_TYPE with key '${pageTypeKey}' (exported in src/views/${viewsFolder}/routes.js)`
        )
      if (pagesMap) {
        Object.entries(pagesMap).forEach(([key, { PATH, VIEW }]) => {
          pages.push({
            key,
            viewsFolder: viewsFolder as ViewsFolder,
            pageType,
            path: PATH,
            view: `${viewsFolder}/${pageType}/${VIEW}`,
          })
        })
      }
    })
  })

  return pages
}
