export const filterByKey = <T extends Record<string, unknown>>(
  obj: T,
  predicate: (key: string) => boolean
): Partial<T> =>
  Object.fromEntries(
    Object.entries(obj).filter(([key]) => predicate(key))
  ) as Partial<T>

export const mapKeys = <T extends Record<string, unknown>>(
  obj: T,
  mapFn: (key: string) => string
): T =>
  Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [mapFn(key), value])
  ) as T
