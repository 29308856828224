import { FAREWILL_COOKIE_DOMAIN, RELEASE_ENV } from 'config'
import {
  getCookieConsent,
  userGrantedConsentForCategory,
} from 'lib/storage/cookies/cookieYes'
import { CookieNames } from 'lib/storage/cookies/constants'
import { getParsedCookie, setCookie } from 'lib/storage/cookies/helpers'

import { hasDNTEnabled } from 'lib/browser/helpers'
import type { FlagName } from 'lib/split/types'

/**
 * https://help.split.io/hc/en-us/articles/360020448791-JavaScript-SDK#user-consent
 * 'GRANTED': the user grants consent for tracking events and impressions.
 *  The SDK sends them to Split cloud. This is the default value if userConsent param is not defined.
 * 'DECLINED': the user declines consent for tracking events and impressions.
 *  The SDK does not send them to Split cloud.
 * 'UNKNOWN': the user neither grants nor declines consent for tracking events and impressions.
 *  The SDK tracks them in its internal storage, and eventually either sends them or not if the consent status is updated to 'GRANTED' or 'DECLINED' respectively.
 *
 *  The status can be updated at any time with the UserConsent.setStatus factory method.
 */

export const getUserConsent = (): SplitIO.ConsentStatus => {
  if (RELEASE_ENV === 'development' || RELEASE_ENV === 'staging') {
    const override = localStorage.getItem(
      'fw.consentOverride'
    ) as SplitIO.ConsentStatus | null
    return override || 'GRANTED'
  }

  // If the user has enabled Do Not Track in their browser
  if (hasDNTEnabled()) return 'DECLINED'

  const cookieConsent = getCookieConsent()
  // If the cookie does not yet exist
  if (!cookieConsent) return 'UNKNOWN'
  // If the cookie exists but has empty string values for consent.
  // It appears this behaviour is a bug in the CookieYes library. We have filed a support ticket with CookieYes to investigate this further: number 17596.
  if (cookieConsent.consent === '') return 'UNKNOWN'
  // If the cookie exists and the user has not yet answered the consent question
  if (cookieConsent.consent === 'no') return 'UNKNOWN'

  // If the cookie exists and the user has granted consent for analytics cookies
  // We use the analytics category as a proxy for the performance category too
  // See notion docs: https://www.notion.so/farewill/Cookies-1cab4b51d61e4999b9fa5cb06fd7545d?pvs=4#224731af6bd243ef897026b64eb8e7ad
  if (userGrantedConsentForCategory('analytics')) return 'GRANTED'

  // If the cookie exists and the user has not granted consent for performance and analytics cookies
  return 'DECLINED'
}

export const updateExperimentCookie = (
  flagName: FlagName,
  treatment: string
): void => {
  const currentCookie = getParsedCookie<
    Partial<{
      [key in FlagName]: string
    }>
  >(CookieNames.EXPERIMENTS)
  setCookie(
    CookieNames.EXPERIMENTS,
    {
      ...currentCookie,
      [flagName]: treatment,
    },
    {
      expires: 180,
      ...(FAREWILL_COOKIE_DOMAIN ? { domain: FAREWILL_COOKIE_DOMAIN } : null),
    }
  )
}
