import { PROBATE, FUNERAL, TELEPHONE_WILLS } from 'lib/products/constants'
import { CookieNames } from 'lib/storage/cookies/constants'

/**
 * Corresponds to conversion events in Google Ads
 *
 * the keys are the name of the conversion action
 * the values are from the 'Tag setup' event value
 *
 * see: https://ads.google.com/aw/conversions?ocid=127388071&euid=372761272&__u=7526279928&uscid=127388071&__c=9416127279&authuser=3
 */

export const GOOGLE_ADS_CONVERSION_LABELS = {
  CALLBACK: {
    [PROBATE]: 'Probate Prospect - Request a Call Back (1pc, 1pv)',
    [FUNERAL]: 'Cremation Prospect - Request a Call Back (1pc, 1pv)',
    [TELEPHONE_WILLS]: 'Phone Will Prospect - Request a Callback (1pc 1pv)',
  },
  CONTACT_FORM: {
    [PROBATE]: 'Probate Prospect - Message Sent (1pc, 1pv)',
  },
  QUOTE: {
    [FUNERAL]: 'Cremation Prospect - Quote Requested (1pc, 1pv)',
  },
}

/**
 * These conversions are from the source 'Website'
 * they are used in the trackConversion function in lib/tracking
 */

export const GOOGLE_ADS_CONVERSION_ACTIONS = {
  [GOOGLE_ADS_CONVERSION_LABELS.CALLBACK[PROBATE]]: 'pCeQCNWvlJYBENnil8QD',
  [GOOGLE_ADS_CONVERSION_LABELS.CALLBACK[FUNERAL]]: 'ShXFCOXS7LMBENnil8QD',
  [GOOGLE_ADS_CONVERSION_LABELS.CALLBACK[TELEPHONE_WILLS]]:
    'Y4gNCJrup88BENnil8QD',
  [GOOGLE_ADS_CONVERSION_LABELS.CONTACT_FORM[PROBATE]]: 'ZO_qCN-42MoBENnil8QD',
  [GOOGLE_ADS_CONVERSION_LABELS.QUOTE[FUNERAL]]: 'MEx_CMzjhssBENnil8QD',
}

/**
 * This conversion is from the source 'Import from calls' with label 'Lead'
 * it is used in TelephoneNumber to dynamically replace all the phone numbers
 * with Google Ads trackable ones
 */

export const GOOGLE_ADS_PHONE_CONVERSION_ACTION = '3nx7CJjU6qkBENnil8QD'

/**
 * These correspond to tracking events for Facebook.
 * The key represents what the event is tracking
 * The value represents the Facebook event name
 * Sometimes the event name might not align with what it is tracking and that is because we are limited in what event names we have on Facebook so we had to pick one.
 */

export const FACEBOOK_EVENTS = {
  CHARITY_PAGE_VISIT: 'StartTrial',
  LEAD: 'Lead',
  ENTER_USER_DETAILS_VIEW: 'CustomizeProduct',
  TELEPHONE_WILL_CALLBACK: 'SubmitApplication',
}

export const SEARCH_ENGINES = [
  'google',
  'bing',
  'yahoo',
  'ecosia',
  'duckduckgo',
]

export enum TRACKING_TYPES {
  DIRECT = '(direct)',
  NOT_SET = '(not set)',
  NONE = '(none)',
  ORGANIC = 'organic',
  REFERRAL = 'referral',
}

export enum ATTRIBUTION_TYPES {
  FIRST_TOUCH = 'first touch',
  LAST_TOUCH = 'last touch',
}

export const COOKIE_NAME_BY_ATTRIBUTION_TYPE = {
  [ATTRIBUTION_TYPES.FIRST_TOUCH]: CookieNames.FIRST_TOUCH_TRACKING_COOKIE_NAME,
  [ATTRIBUTION_TYPES.LAST_TOUCH]: CookieNames.LAST_TOUCH_TRACKING_COOKIE_NAME,
}

export const TRACKING_PARAMETERS = [
  'googleClickId',
  'utmSource',
  'utmMedium',
  'utmCampaign',
  'utmTerm',
  'utmContent',
  'utmChannel',
  'activePath',
  'landingPath',
  'partnerId',
]

export const TRACKING_ATTRIBUTE_PREFIX = 'data-track'
