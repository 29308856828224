const PUBLIC = {
  DIRECT: {
    PATH: '/funerals/direct-cremation',
    VIEW: 'Direct',
  },
  // For the header link in logotype, each product needs overview page
  // This can be adjusted after funeral plans go live, but for now we need this
  OVERVIEW: {
    PATH: '/funerals/direct-cremation',
    VIEW: 'Direct',
  },
  DIRECT_CREMATION_TERMS: {
    PATH: 'funerals/direct-cremations-terms',
    VIEW: 'Terms',
  },
  FUNERAL_PLANS_TERMS: {
    PATH: '/funerals/funeral-plans-terms',
    VIEW: 'Terms',
  },

  COMPARE: {
    PATH: '/funerals/compare',
    VIEW: 'Compare',
  },
  FUNERAL_PLAN_GET_A_GUIDE: {
    PATH: '/funerals/get-a-guide',
    VIEW: 'GetAGuide',
  },
  FUNERAL_PLAN_GET_A_GUIDE_FORM: {
    PATH: '/funerals/get-a-guide/form',
    VIEW: 'GetAGuideForm',
  },
  FUNERAL_PLAN_GET_A_GUIDE_CALLBACK: {
    PATH: '/funerals/get-a-guide/callback',
    VIEW: 'GetAGuideCallback',
  },
  COMMISSION_DISCLOSURE: {
    PATH: '/funerals/disclosure',
    VIEW: 'CommissionDisclosureUpfront',
  },
  COMMISSION_DISCLOSURE_INSTALMENTS: {
    PATH: '/funerals/disclosure-instalments',
    VIEW: 'CommissionDisclosureInstalments',
  },
  FUNERAL_PLANS: {
    PATH: '/funerals/funeral-plans',
    VIEW: 'FuneralPlans',
  },
  FUNERAL_OVERVIEW: {
    PATH: '/funerals',
    VIEW: 'FuneralOverview',
  },
}

const PPC = {
  DIRECT_EXPERIMENT: {
    PATH: '/landing/direct-cremation',
    VIEW: 'DirectExperiment',
  },
  FUNERAL_SIMPLE_CORE_LOCAL: {
    PATH: '/landing/cremation-simple',
    VIEW: 'SimpleCoreLocal',
  },
  FUNERAL_PLANS: {
    PATH: '/landing/funeral-plans',
    VIEW: 'FuneralPlans',
  },
  FUNERAL_OVERVIEW: {
    PATH: '/landing/funerals',
    VIEW: 'FuneralOverview',
  },
}

export const ROUTES = { PUBLIC, PPC }
