const toLocaleStringSupportsOptions = !!(
  typeof Intl === 'object' &&
  Intl &&
  typeof Intl.NumberFormat === 'function'
)

/**
 * Formats a number into a string of pounds,
 * with 2 decimal places if it isn't an integer
 *
 * @param {number} number The amount of pounds to format
 */

export const formatPriceInPounds = (number: number): string => {
  const numberIsInteger = number % 1 === 0

  if (toLocaleStringSupportsOptions) {
    return number.toLocaleString('en-GB', {
      style: 'currency',
      currency: 'GBP',
      minimumFractionDigits: numberIsInteger ? 0 : 2,
    })
  }

  return `£${numberIsInteger ? number : number.toFixed(2)}`
}
