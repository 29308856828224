import React, { useContext, useLayoutEffect } from 'react'
import { SplitContext } from '@splitsoftware/splitio-react'
import { useSplitCookieYesConsent } from 'lib/hooks/useSplitCookieYesConsent'

export const AppWrapper = ({
  element,
}: {
  element: React.ReactElement
}): React.ReactElement => {
  const { isReady, isTimedout } = useContext(SplitContext)

  useLayoutEffect(() => {
    if (isReady || isTimedout) {
      if (document.documentElement.dataset.splitMask) {
        document.documentElement.dataset.splitMask = 'false'
      }
    }
  }, [isReady, isTimedout])

  /**
   * Check user consents to tracking and update Split SDK accordingly.
   */
  useSplitCookieYesConsent()

  return element
}
