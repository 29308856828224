import { RELEASE_ENV } from 'config'

declare global {
  interface Window {
    dataLayer?: unknown[]
  }
}

/**
 * Custom impression listener that pushes impression data onto the dataLayer for
 * Google Tag Manager.
 *
 * Note that just pushing new properties into the dataLayer does not mean that
 * they will be forwarded on to Google Analytics - Extra setup is required. See
 * https://www.notion.so/farewill/Forwarding-variant-info-to-Google-Analytics-via-GTM-e440b38d8b9b4d0f8f56af20e3759899?pvs=4
 */
export class GtmImpressionListener implements SplitIO.IImpressionListener {
  logImpression = ({ impression }: SplitIO.ImpressionData): void => {
    if (RELEASE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.debug(
        '[Ga4ImpressionListener]',
        `${impression.treatment}: ${impression.feature}`
      )
    }

    window.dataLayer?.push({
      event: 'SetUserFlags',
      splitClientId: impression.keyName,
      [impression.feature]: impression.treatment,
    })

    window.dataLayer?.push({
      event: 'SplitImpression',
      splitClientId: impression.keyName,
      flag: impression.feature,
      treatment: impression.treatment,
    })
  }
}
