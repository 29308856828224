import { PAGE_TYPE, PAGE_TYPE_KEYS, VIEWS_FOLDER_KEYS } from './constants'
import { PageTypeKey, ViewsFolder } from './types'

export interface Pages {
  key: string
  viewsFolder: ViewsFolder
  pageType: PAGE_TYPE
  path: string
  view: string
}
type SinglePath = Record<string, Pages['path']>
type Values = SinglePath & Record<PageTypeKey, SinglePath>
type Paths = Record<ViewsFolder, Values>

export const getPathsObject = (pagesArray: Pages[]): Paths => {
  const paths = {} as Paths
  pagesArray.forEach(({ viewsFolder, pageType, key, path }) => {
    const viewsFolderKey = VIEWS_FOLDER_KEYS[viewsFolder] as ViewsFolder
    const pageTypeKey = PAGE_TYPE_KEYS[pageType] as PageTypeKey
    paths[viewsFolderKey] = paths[viewsFolderKey] || ({} as Values)
    if (pageType === PAGE_TYPE.PUBLIC) {
      paths[viewsFolderKey][key] = path
    } else {
      paths[viewsFolderKey][pageTypeKey] =
        paths[viewsFolderKey][pageTypeKey] || {}
      paths[viewsFolderKey][pageTypeKey][key] = path
    }
  })
  return paths
}
