import { backOff } from 'exponential-backoff'

import { getGoogleClickId } from 'lib/tracking/googleAdsClickIdentifier'
import { getQueryParameters } from 'lib/url/getQueryParameters'
import { CookieNames } from 'lib/storage/cookies/constants'
import { getCookie } from 'lib/storage/cookies/helpers'
import {
  isInternalReferral,
  formatParameters,
  getSnowplowId,
  getOptimiseExp,
  getSnowplowIdAsync,
  getOptimiseExpAsync,
} from './helpers'

import { TrackingParameters } from './interfaces'
import { SEARCH_ENGINES, TRACKING_TYPES } from './constants'

const { FIRST_TOUCH_TRACKING_COOKIE_NAME, LAST_TOUCH_TRACKING_COOKIE_NAME } =
  CookieNames

export const getReferrerDomainName = (): string | null => {
  if (!document.referrer) return null

  try {
    const { hostname } = new URL(document.referrer)
    return hostname.replace(/^(www\.)/, '')
  } catch {
    return null
  }
}

export const getReferralSource = (): {
  utmSource: string
  utmMedium: string
} => {
  const referrerDomainName = getReferrerDomainName()

  if (referrerDomainName) {
    const internalReferral = isInternalReferral(referrerDomainName)
    const searchEngine = SEARCH_ENGINES.find((name) =>
      new RegExp(name, 'i').test(referrerDomainName)
    )

    if (searchEngine) {
      return { utmSource: searchEngine, utmMedium: TRACKING_TYPES.ORGANIC }
    }

    if (!internalReferral) {
      return {
        utmSource: referrerDomainName,
        utmMedium: TRACKING_TYPES.REFERRAL,
      }
    }
  }
  return { utmSource: TRACKING_TYPES.DIRECT, utmMedium: TRACKING_TYPES.NONE }
}

export const gatherTrackingParams = (
  partnerPageUtms: Partial<TrackingParameters>
) => {
  const queryParameters = getQueryParameters()
  const storedGoogleClickId = getGoogleClickId()?.value
  const referralSource = getReferralSource()

  const parameters =
    Object.keys(partnerPageUtms || {}).length > 0
      ? partnerPageUtms
      : queryParameters

  return {
    utmCampaign: TRACKING_TYPES.NOT_SET,
    utmTerm: TRACKING_TYPES.NOT_SET,
    utmContent: TRACKING_TYPES.NOT_SET,
    utmChannel: TRACKING_TYPES.NOT_SET,
    googleClickId: storedGoogleClickId || TRACKING_TYPES.NOT_SET,
    landingPath: window.location.pathname || TRACKING_TYPES.NOT_SET,
    snowplowId: getSnowplowId() || TRACKING_TYPES.NONE,
    optimiseExp: getOptimiseExp() || TRACKING_TYPES.NONE,
    partnerId: TRACKING_TYPES.NOT_SET,
    ...referralSource,
    ...formatParameters(parameters),
  }
}

export const hasSavedTrackingParams = (): boolean =>
  !!getCookie(LAST_TOUCH_TRACKING_COOKIE_NAME)

export const hasSavedFirstTouchTrackingParams = (): boolean =>
  !!getCookie(FIRST_TOUCH_TRACKING_COOKIE_NAME)

export const gatherTrackingParamAsync = async (
  trackingParamRequest: () => Promise<string | undefined>
): Promise<string | undefined> => {
  try {
    const res = await backOff(trackingParamRequest, {
      numOfAttempts: 5,
    })
    return res
  } catch {
    return undefined
  }
}

export const gatherTrackingParamsAsync = async (): Promise<{
  snowplowId: string | undefined
  optimiseExp: string | undefined
}> => {
  const [snowplowId, optimiseExp] = await Promise.all([
    gatherTrackingParamAsync(getSnowplowIdAsync),
    gatherTrackingParamAsync(getOptimiseExpAsync),
  ])

  return { snowplowId, optimiseExp }
}
