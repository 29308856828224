import { VIEWS_FOLDER } from 'lib/routing/constants'
import { getPagesArray } from 'lib/routing/pages'

import { ROUTES as GENERAL_ROUTES } from 'views/general/routes'
import { ROUTES as WILLS_ROUTES } from 'views/wills/routes'
import { ROUTES as PROBATE_ROUTES } from 'views/probate/routes'
import { ROUTES as CREMATION_ROUTES } from 'views/cremation/routes'
import { ROUTES as PREVIEWS_ROUTES } from 'views/previews/routes'

const routeFolderMap = {
  [VIEWS_FOLDER.GENERAL]: GENERAL_ROUTES,
  [VIEWS_FOLDER.WILLS]: WILLS_ROUTES,
  [VIEWS_FOLDER.PROBATE]: PROBATE_ROUTES,
  [VIEWS_FOLDER.CREMATION]: CREMATION_ROUTES,
  [VIEWS_FOLDER.PREVIEWS]: PREVIEWS_ROUTES,
}

const PAGES = getPagesArray(routeFolderMap)

/**
 * Returns an array of objects with the following shape:
 * {
 *   key: 'HOME',
 *   path: '/',
 *   view: 'general/public/Home',
 *   viewsFolder: 'general', // VIEWS_FOLDER.GENERAL
 *   pageType: 'public', // PAGE_TYPE.PUBLIC
 * }
 */

export default PAGES
