import {
  FIRST_TOUCH_TRACKING_COOKIE_NAME,
  LAST_TOUCH_TRACKING_COOKIE_NAME,
  WILLS_REFERRAL_PARAMS_COOKIE_NAME,
} from 'config'

/**
 * [1] This is the name of the cookie that can be used to force our pages to
 *     show the out of hours state or the in hours state even when it's out of
 *     hours. In the out of hours state many phone numbers are replaced with
 *     callback links. There are instructions on how to set this cookie locally
 *     in the README, and there are helpers available to set it in tests.
 */
export const CookieNames: Record<string, string> = {
  COOKIE_BANNER_HIDDEN: 'cookieBannerHidden',
  CLIENT_ID: 'fwClientId',
  CSRF: '_csrf',
  FIRST_TOUCH_TRACKING_COOKIE_NAME,
  LAST_TOUCH_TRACKING_COOKIE_NAME,
  ONBOARDING: 'onboarding',
  OUT_OF_HOURS_STATE_OVERRIDE: 'outOfHoursStateOverride', // [1]
  EXPERIMENTS: 'fwExperiments',
  WILLS_REFERRAL_PARAMS_COOKIE_NAME,
} as const

/**
 * [1] We don't actually use a separate category for performance cookies as we
 *     group them with analytics cookies. As a result we have configured
 *     CookieYes to hide this category from the Cookie dialog.
 *     See https://www.notion.so/farewill/Cookies-1cab4b51d61e4999b9fa5cb06fd7545d?pvs=4#224731af6bd243ef897026b64eb8e7ad
 */
export const CookieCategories = {
  necessary: [
    CookieNames.CLIENT_ID,
    CookieNames.COOKIE_BANNER_HIDDEN,
    CookieNames.CSRF,
    CookieNames.ONBOARDING,
    CookieNames.WILLS_REFERRAL_PARAMS_COOKIE_NAME,
  ],
  functional: [CookieNames.OUT_OF_HOURS_STATE_OVERRIDE],
  analytics: [
    CookieNames.EXPERIMENTS,
    CookieNames.FIRST_TOUCH_TRACKING_COOKIE_NAME,
    CookieNames.LAST_TOUCH_TRACKING_COOKIE_NAME,
  ],
  performance: [], // [1]
  advertisement: [],
} as const
