import { useEffect } from 'react'
import { useTrack } from '@splitsoftware/splitio-react'
import { snakeCase } from 'lodash-es'

import { RELEASE_ENV } from '../../src/config'
import { TRACKING_ATTRIBUTE_PREFIX } from '../../src/lib/tracking/constants'

import { filterByKey, mapKeys } from '../../src/lib/object/helpers'

export const useSplitEventTracking = () => {
  const track = useTrack()
  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement
      const element = target.closest(
        `a[${TRACKING_ATTRIBUTE_PREFIX}-event], button[${TRACKING_ATTRIBUTE_PREFIX}-event]`
      ) as HTMLElement | null

      if (!element) {
        return
      }

      const TRACKING_PREFIX = TRACKING_ATTRIBUTE_PREFIX.slice('data-'.length)
      const stripPrefixAndSnakeCase = (key: string) => {
        const strippedKey = key.slice(TRACKING_PREFIX.length)
        return snakeCase(strippedKey)
      }

      const type = target.closest('button') ? 'button' : 'link'
      const eventName = element.dataset.trackEvent as string
      const trackingData = filterByKey(element.dataset, (key) =>
        key.startsWith(TRACKING_PREFIX)
      )
      const properties = {
        text: element.innerText,
        source_page_href: window.location.href,
        source_page_origin: window.location.origin,
        source_page_path: window.location.pathname,
        ...mapKeys(trackingData, stripPrefixAndSnakeCase),
      }

      if (type === 'link') {
        const link = target.closest('a')
        if (!link) {
          return
        }

        const linkUrl = new URL(link.href)
        Object.assign(properties, {
          link_href: linkUrl.href,
          link_origin: linkUrl.origin,
          link_path: linkUrl.pathname,
        })
      }

      if (RELEASE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.log(
          `[splitio-plugin] Tracking ${eventName} event with properties`,
          properties
        )
      }

      track(eventName, undefined, properties)
    }
    document.addEventListener('auxclick', handleClick, { capture: true })
    document.addEventListener('click', handleClick, { capture: true })

    return () => {
      document.removeEventListener('auxclick', handleClick, { capture: true })
      document.removeEventListener('click', handleClick, { capture: true })
    }
  }, [])
}
