import { useState, useEffect } from 'react'
import * as Sentry from '@sentry/gatsby'

import { USE_CALL_TRACKING, GENERAL_TELEPHONE_NUMBER } from 'config'

declare global {
  interface Window {
    CallTrk: {
      getSwapNumbers: (
        phoneNumberToSwap: string[],
        callback: (result: Record<string, string>) => void
      ) => Record<string, string>
    }
  }
}

export const useCallRailTracking = (): {
  loadedTracking: boolean
  trackedPhoneNumber: string
} => {
  const [loadedTracking, setLoadedTracking] = useState(!USE_CALL_TRACKING)
  const [trackedPhoneNumber, setTrackedPhoneNumber] = useState('')

  const swapNumbers = () => {
    if (window && window.CallTrk) {
      window.CallTrk.getSwapNumbers([GENERAL_TELEPHONE_NUMBER], (result) => {
        if (result) {
          const swappedNumber = Object.values(result)[0]
          setTrackedPhoneNumber(swappedNumber)
        }
        setLoadedTracking(true)
      })
    } else {
      Sentry.captureException(new Error('CallRail window object not found'), {
        tags: { tracking: 'callrail' },
      })
    }
  }

  useEffect(() => {
    if (USE_CALL_TRACKING && !loadedTracking) {
      const script = document.createElement('script')
      script.src = `//cdn.callrail.com/companies/590877000/9808c7291c091ccbbcf6/12/swap.js`
      script.async = true
      document.head.appendChild(script)
      script.addEventListener('load', swapNumbers)
      script.addEventListener('error', (error) => {
        Sentry.captureException(error, {
          tags: { tracking: 'callrail' },
        })
        setLoadedTracking(true)
      })
    }
  }, [])

  return { loadedTracking, trackedPhoneNumber }
}
