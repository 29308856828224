import axios from 'axios'
import * as Sentry from '@sentry/gatsby'

import {
  GOOGLE_ANALYTICS_ID,
  GOOGLE_ADWORDS_ID,
  FAREWILL_BACKSTAGE_URL,
  USE_CALL_TRACKING,
} from 'config'

import { GOOGLE_ADS_CONVERSION_ACTIONS, TRACKING_TYPES } from './constants'
import { getSavedTrackingParams } from './trackingParams'
import { TrackingParameters } from './interfaces'

declare global {
  interface Window {
    gtag: (type: string, id: string, data: Record<string, unknown>) => void
    fbq: (type: string, event: string) => void
    uetq: (string | Record<string, string>)[]
    snowplow?: () => string
    doNotTrack?: string
    adalyserTracker?: (
      type: string,
      id: string,
      data: Record<string, unknown>,
      extraOption?: boolean
    ) => void
  }
}

interface MsNavigator extends Navigator {
  msDoNotTrack: string
}

/**
 * Returns true if user doesn't want to be tracked, false otherwise
 * Original code from: https://github.com/Ephys/do-not-track
 * Copied from frontend
 *
 * @returns {!boolean} The user agrees to being tracked.
 */
export const doNotTrack = (): boolean => {
  if (typeof window === 'undefined') {
    return true
  }

  const doNotTrackOption =
    window.doNotTrack ||
    window.navigator.doNotTrack ||
    (window.navigator as MsNavigator).msDoNotTrack

  if (!doNotTrackOption) {
    return false
  }

  if (doNotTrackOption.charAt(0) === '1' || doNotTrackOption === 'yes') {
    return true
  }

  if (doNotTrackOption.charAt(0) === '0' || doNotTrackOption === 'no') {
    return false
  }

  return false
}

export const trackPageChange = (pathname: string): void => {
  if (doNotTrack()) return

  if (window.gtag) {
    window.gtag('config', GOOGLE_ANALYTICS_ID, {
      page_path: pathname,
    })
  }
}

export const trackFacebookEvent = (event: string): void => {
  if (doNotTrack()) return

  if (window.fbq) {
    window.fbq('track', event)
  }
}

export const trackEvent = (
  category: string,
  action: string,
  label?: string
): void => {
  if (doNotTrack() || !window.gtag) return

  window.gtag('event', action, {
    event_category: category,
    event_label: label || window.location.pathname,
  })
}

// Script used for tracking the impact of TV campaign on Funeral Plans sales
export const trackFuneralPlanAdConvertion = (): void => {
  if (doNotTrack() || !window.adalyserTracker) return
  window.adalyserTracker('trackEvent', 'lce2', { a2: 'Funeral Plan' }, true)
}

// Script used for tracking the impact of TV campaign on Funeral  visits
export const trackAtNeedAdConvertion = (): void => {
  if (doNotTrack() || !window.adalyserTracker) return
  window.adalyserTracker('trackEvent', 'lce5', { a5: 'Funeral' })
}

export const trackConversion = (action: string): void => {
  if (doNotTrack() || !window.gtag || !GOOGLE_ADS_CONVERSION_ACTIONS[action])
    return

  window.gtag('event', 'conversion', {
    send_to: `${GOOGLE_ADWORDS_ID}/${GOOGLE_ADS_CONVERSION_ACTIONS[action]}`,
  })
}

export const trackPartnerCampaignPageVisit = (
  trackingParams: TrackingParameters
): void => {
  if (doNotTrack() || !window.gtag) return

  window.gtag('config', GOOGLE_ANALYTICS_ID, {
    campaign: {
      name: trackingParams.utmCampaign,
      source: trackingParams.utmSource,
      medium:
        trackingParams.utmMedium === TRACKING_TYPES.NOT_SET
          ? 'website'
          : trackingParams.utmMedium,
      content: trackingParams.utmContent,
      term: trackingParams.utmTerm,
    },
  })
}

export const trackBingProspect = ({
  category,
}: {
  category: 'funeral_plans'
}): void => {
  if (doNotTrack() || !window.uetq) return

  if (!category) return

  window.uetq.push('event', 'submit', {
    event_category: category,
    event_label: 'quoteflow',
  })
}

export const trackLocalFuneralPageVisit = async (
  phoneNumber: string
): Promise<void> => {
  if (USE_CALL_TRACKING) {
    try {
      const trackingParameters = getSavedTrackingParams()
      trackingParameters.forEach(async (parameters) => {
        await axios.post(`${FAREWILL_BACKSTAGE_URL}/api/tracking-parameters`, {
          data: {
            type: 'tracking-parameters',
            attributes: {
              ...parameters,
              phoneNumberShownToUser: phoneNumber,
            },
          },
        })
      })
    } catch (error) {
      Sentry.captureException(error)
    }
  }
}
