import appConfig from '../app.json'

export const RELEASE_ENV =
  process.env.GATSBY_RELEASE_ENV || appConfig.env.GATSBY_RELEASE_ENV.value
export const GIT_HASH = process.env.GATSBY_GIT_HASH

export const ROOT_URL =
  process.env.GATSBY_ROOT_URL || appConfig.env.GATSBY_ROOT_URL.value
export const FAREWILL_API_URL =
  process.env.GATSBY_FAREWILL_API_URL ||
  appConfig.env.GATSBY_FAREWILL_API_URL.value
export const FAREWILL_BACKSTAGE_URL =
  process.env.GATSBY_FAREWILL_BACKSTAGE_URL ||
  appConfig.env.GATSBY_FAREWILL_BACKSTAGE_URL.value
export const FAREWILL_WILLS_URL =
  process.env.GATSBY_FAREWILL_WILLS_URL ||
  appConfig.env.GATSBY_FAREWILL_WILLS_URL.value
export const FAREWILL_ACCOUNT_APP_URL =
  process.env.GATSBY_ACCOUNT_APP_URL ||
  appConfig.env.GATSBY_ACCOUNT_APP_URL.value
export const FLOW_FUNERAL_URL =
  process.env.GATSBY_FLOW_FUNERAL_URL ||
  appConfig.env.GATSBY_FLOW_FUNERAL_URL.value
export const FLOW_FUNERAL_PLAN_URL =
  process.env.GATSBY_FLOW_FUNERAL_PLAN_URL ||
  appConfig.env.GATSBY_FLOW_FUNERAL_PLAN_URL.value
export const FLOW_PROBATE_URL =
  process.env.GATSBY_FLOW_PROBATE_URL ||
  appConfig.env.GATSBY_FLOW_PROBATE_URL.value
export const FLOW_WILLS_URL =
  process.env.GATSBY_FLOW_WILLS_URL || appConfig.env.GATSBY_FLOW_WILLS_URL.value

export const FIRST_TOUCH_TRACKING_COOKIE_NAME =
  process.env.GATSBY_FIRST_TOUCH_TRACKING_COOKIE_NAME ||
  appConfig.env.GATSBY_FIRST_TOUCH_TRACKING_COOKIE_NAME.value

export const FRONT_APP_CHAT_ID =
  process.env.GATSBY_FRONT_APP_CHAT_ID ||
  appConfig.env.GATSBY_FRONT_APP_CHAT_ID.value

export const FUNERAL_PLANS_FRONT_APP_CHAT_ID =
  process.env.GATSBY_FUNERAL_PLANS_FRONT_APP_CHAT_ID ||
  appConfig.env.GATSBY_FUNERAL_PLANS_FRONT_APP_CHAT_ID.value

export const LAST_TOUCH_TRACKING_COOKIE_NAME =
  process.env.GATSBY_LAST_TOUCH_TRACKING_COOKIE_NAME ||
  appConfig.env.GATSBY_LAST_TOUCH_TRACKING_COOKIE_NAME.value

export const WILLS_REFERRAL_PARAMS_COOKIE_NAME =
  process.env.GATSBY_WILLS_REFERRAL_PARAMS_COOKIE_NAME ||
  appConfig.env.GATSBY_WILLS_REFERRAL_PARAMS_COOKIE_NAME.value

export const FAREWILL_COOKIE_DOMAIN =
  (process.env.GATSBY_FAREWILL_COOKIE_DOMAIN === 'null'
    ? null
    : process.env.GATSBY_FAREWILL_COOKIE_DOMAIN) ||
  appConfig.env.GATSBY_FAREWILL_COOKIE_DOMAIN.value

export const TRACKING_COOKIE_DOMAIN =
  (process.env.GATSBY_TRACKING_COOKIE_DOMAIN === 'null'
    ? null
    : process.env.GATSBY_TRACKING_COOKIE_DOMAIN) ||
  appConfig.env.GATSBY_TRACKING_COOKIE_DOMAIN.value

export const SENTRY_DSN =
  process.env.GATSBY_SENTRY_DSN || appConfig.env.GATSBY_SENTRY_DSN.value
export const SENTRY_SOURCE_MAP_AUTH_TOKEN =
  process.env.GATSBY_SENTRY_SOURCE_MAP_AUTH_TOKEN ||
  appConfig.env.GATSBY_SENTRY_SOURCE_MAP_AUTH_TOKEN.value

export const ZENDESK_APP_ID =
  process.env.GATSBY_ZENDESK_APP_ID || appConfig.env.GATSBY_ZENDESK_APP_ID.value
export const ZENDESK_HELP_CENTER_URL =
  process.env.GATSBY_ZENDESK_HELP_CENTER_URL ||
  appConfig.env.GATSBY_ZENDESK_HELP_CENTER_URL.value

export const CALENDLY_FUNERAL_PLANS_GUIDE_APPOINTMENT_URL =
  process.env.GATSBY_CALENDLY_FUNERAL_PLANS_GUIDE_APPOINTMENT_URL ||
  appConfig.env.GATSBY_CALENDLY_FUNERAL_PLANS_GUIDE_APPOINTMENT_URL.value

export const TELEPHONE_WILLS_APPOINTMENT_BOOKING_URL =
  process.env.GATSBY_TELEPHONE_WILLS_APPOINTMENT_BOOKING_URL ||
  appConfig.env.GATSBY_TELEPHONE_WILLS_APPOINTMENT_BOOKING_URL.value

export const PROBATE_APPOINTMENT_BOOKING_URL =
  process.env.GATSBY_PROBATE_APPOINTMENT_BOOKING_URL ||
  appConfig.env.GATSBY_PROBATE_APPOINTMENT_BOOKING_URL.value

export const PARTNER_HUB_URL = process.env.GATSBY_PARTNER_HUB_URL
export const SPOTLIGHT_URL = process.env.GATSBY_SPOTLIGHT_URL

export const GOOGLE_ANALYTICS_ID =
  process.env.GATSBY_GOOGLE_ANALYTICS_ID ||
  appConfig.env.GATSBY_GOOGLE_ANALYTICS_ID.value
export const GOOGLE_TAG_MANAGER_ID =
  process.env.GATSBY_GOOGLE_TAG_MANAGER_ID ||
  appConfig.env.GATSBY_GOOGLE_TAG_MANAGER_ID.value
export const GOOGLE_ADWORDS_ID =
  process.env.GATSBY_GOOGLE_ADWORDS_ID ||
  appConfig.env.GATSBY_GOOGLE_ADWORDS_ID.value
export const HOTJAR_SITE_ID =
  process.env.GATSBY_HOTJAR_SITE_ID || appConfig.env.GATSBY_HOTJAR_SITE_ID.value
export const FACEBOOK_PIXEL_ID =
  process.env.GATSBY_FACEBOOK_PIXEL_ID ||
  appConfig.env.GATSBY_FACEBOOK_PIXEL_ID.value
export const BING_ADS_ID =
  process.env.GATSBY_BING_ADS_ID || appConfig.env.GATSBY_BING_ADS_ID.value
export const COOLADATA_KEY =
  process.env.GATSBY_COOLADATA_KEY || appConfig.env.GATSBY_COOLADATA_KEY.value
export const USE_CALL_TRACKING = JSON.parse(
  process.env.GATSBY_USE_CALL_TRACKING ||
    appConfig.env.GATSBY_USE_CALL_TRACKING.value
)
export const USE_CELEBRATIONS_FONT_TRACKING =
  process.env.GATSBY_USE_CELEBRATIONS_FONT_TRACKING ||
  appConfig.env.GATSBY_USE_CELEBRATIONS_FONT_TRACKING.value
export const SPLIT_CLIENT_KEY =
  process.env.GATSBY_SPLIT_CLIENT_KEY ||
  appConfig.env.GATSBY_SPLIT_CLIENT_KEY.value
export const FLAG_BUILD_CONTENTFUL_PAGES = JSON.parse(
  process.env.GATSBY_FLAG_BUILD_CONTENTFUL_PAGES ||
    appConfig.env.GATSBY_FLAG_BUILD_CONTENTFUL_PAGES.value
)

export const CHARITY_AMOUNT_RAISED =
  process.env.GATSBY_CHARITY_AMOUNT_RAISED ||
  appConfig.env.GATSBY_CHARITY_AMOUNT_RAISED.value
export const FAREWILL_BLOG_URL = 'https://medium.com/farewill'
export const FAREWILL_JOBS_URL = 'https://farewill.workable.com'
export const FAREWILL_TWITTER_URL = 'https://twitter.com/farewill'
export const FAREWILL_FACEBOOK_URL = 'https://facebook.com/getfarewill'

export const GENERAL_TELEPHONE_NUMBER = '02036952090'
export const GENERAL_EMAIL_ADDRESS = 'hello@farewill.com'
export const LEGAL_EMAIL_ADDRESS = 'legalteam@farewill.com'
export const SECURITY_EMAIL_ADDRESS = 'security@farewill.com'

export const WILLS_TELEPHONE_NUMBER = '02080502686'
export const WILLS_SUPPORT_START_TIME = 9
export const WILLS_SUPPORT_END_TIME = 18
export const WILLS_SUPPORT_ISO_DAYS_OF_WEEK = [1, 2, 3, 4, 5]
export const WILLS_SUPPORT_OVERFLOW = false
export const WILLS_SIGN_UP_PATH = '/start/relationship'
export const WILLS_NEW_TRIAGE_FLOW_FRAMING_URL = `${FAREWILL_ACCOUNT_APP_URL}/wills/find-a-will`
export const TELEPHONE_WILLS_CALLBACK_HOURS_GUARANTEE = 24
export const TELEPHONE_WILLS_TELEPHONE_NUMBER = '02033184279'

export const TRUSTPILOT_REVIEWS_TOTAL = parseInt(
  process.env.GATSBY_TRUSTPILOT_REVIEWS_TOTAL ||
    appConfig.env.GATSBY_TRUSTPILOT_REVIEWS_TOTAL.value,
  10
)
export const TRUSTPILOT_RATING_OUT_OF_5 = 4.9
export const TRUSTPILOT_URL = 'https://uk.trustpilot.com/review/farewill.com'

export const INHERITANCE_TAX_THRESHOLD = 325000
export const ONLINE_WILL_PRICE_IN_POUNDS =
  parseInt(process.env.GATSBY_ONLINE_WILL_PRICE, 10) / 100
export const ONLINE_WILL_COUPLES_PRICE_IN_POUNDS =
  ONLINE_WILL_PRICE_IN_POUNDS +
  parseInt(process.env.GATSBY_ONLINE_WILL_SUBSEQUENT_PRICE, 10) / 100
export const ONLINE_WILL_SUBSCRIPTION_PRICE_IN_POUNDS =
  parseInt(process.env.GATSBY_ONLINE_WILL_SUBSCRIPTION_PRICE, 10) / 100
export const TELEPHONE_WILL_PRICE_IN_POUNDS =
  parseInt(process.env.GATSBY_TELEPHONE_WILL_PRICE, 10) / 100
export const TELEPHONE_WILL_COUPLES_PRICE_IN_POUNDS =
  TELEPHONE_WILL_PRICE_IN_POUNDS +
  parseInt(process.env.GATSBY_TELEPHONE_WILL_SUBSEQUENT_PRICE, 10) / 100
export const TELEPHONE_WILL_TRUST_PRICE_IN_POUNDS =
  parseInt(process.env.GATSBY_TELEPHONE_WILL_TRUST_PRICE, 10) / 100
export const TELEPHONE_WILL_ADDITIONAL_COMPLEXITY_PRICE_IN_POUNDS =
  parseInt(process.env.GATSBY_TELEPHONE_WILL_ADDITIONAL_COMPLEXITY_PRICE, 10) /
  100
export const PRINTED_WILL_PRICE_IN_POUNDS =
  (parseInt(process.env.GATSBY_PRINTED_WILL_DISPLAY_PRICE, 10) +
    parseInt(process.env.GATSBY_PRINTED_WILL_SHIPPING_DISPLAY_PRICE, 10)) /
  100

export const PROBATE_TELEPHONE_NUMBER = '02036951713'
export const PROBATE_TELEPHONE_NUMBER_EXPERIMENT = '03308185601'
export const PROBATE_EMAIL = 'probate@farewill.com'
export const PROBATE_SUPPORT_START_TIME = 9
export const PROBATE_SUPPORT_END_TIME = 18
export const PROBATE_SUPPORT_ISO_DAYS_OF_WEEK = [1, 2, 3, 4, 5, 6]
export const PROBATE_SUPPORT_OVERFLOW = false
export const PROBATE_SIMPLE_FEE = 595
export const PROBATE_COMPLEX_FEE = 1045
export const PROBATE_ESTATE_ADMIN_FEE = 2000
export const PROBATE_REGISTRY_FEE = 300
export const PROBATE_COPY_FEE = 1.5
export const PROBATE_WORKING_DAYS_GUARANTEE = 7

export const BURIAL_FUNERAL_FEE = 3250

export const CREMATION_TELEPHONE_NUMBER = '02039663935'
export const FUNERALS_SUPPORT_EMAIL = 'funerals.support@farewill.com'
export const FUNERAL_PLAN_TELEPHONE_NUMBER = '03308183830'
// ExptCostCompareFuneralPlansLP - Remove after experiment is done
export const FUNERAL_PLAN_TELEPHONE_NUMBER_EXPERIMENT = '03308084338'
export const FUNERAL_PLAN_EMAIL = 'funeralplan@farewill.com'
export const CREMATION_SUPPORT_START_TIME = 9
export const CREMATION_SUPPORT_END_TIME = 18
export const CREMATION_SUPPORT_ISO_DAYS_OF_WEEK = [1, 2, 3, 4, 5, 6]
export const CREMATION_SUPPORT_OVERFLOW = true
export const CREMATION_SIMPLE_FEE = 950
export const CREMATION_DELIVERY_FEE = 100
export const CREMATION_FUNERAL_PLAN_FEE = 1595
export const CREMATION_FUNERAL_PLAN_MONTHLY_FULL_FEE = 1695
export const CREMATION_FUNERAL_PLAN_MONTHLY_INSTALMENT_FEE = 70.62
export const CREMATION_FUNERAL_PLAN_INSTALMENT_FEE = 100
export const CREMATION_MEDICAL_DEVICE_REMOVAL_FEE = 90
export const CREMATION_URGENT_COLLECTION_FEE = 275
export const CREMATION_CARE_CENTRES = 86

export const CREMATION_CHEAPER_THAN_AVERAGE_UK_FUNERAL_PRICE = 3000
export const CREMATION_PERCENTAGE_CHEAPER_THAN_AVERAGE_UK_FUNERAL = '78%'
export const FUNERAL_AVERAGE_COST_IN_UK = 4056 // Source: https://www.sunlife.co.uk/funeral-costs/

export const CREMATION_AND_PROBATE_TELEPHONE_NUMBER = '02039665322'

export const WHAT_TO_DO_WHEN_SOMEONE_DIES_TELEPHONE_NUMBER = '02033183029'

export const PRESS_TEAM_EMAIL = 'press@farewill.com'
export const PRESS_KIT_URL =
  'https://drive.google.com/drive/folders/1a-GZUV86XpNrIVgW1YieZfkcbQRF1R8F?usp=sharing'

export const SECURITY_REPORTING_PUBLIC_KEY_URL =
  'https://s3-eu-west-1.amazonaws.com/farewill-app/site/pgp_public_key.txt'

export const WHAT_TO_DO_WHEN_SOMEONE_DIES_PDF_URL =
  'https://farewill-app.s3.eu-west-1.amazonaws.com/site/resources/what-to-do-when-someone-dies-checklist.pdf'

export const STANDARDISED_PRICING_LIST_PDF_URL =
  'https://farewill-app.s3.eu-west-1.amazonaws.com/site/resources/standardised-price-list.pdf'

export const ADDITIONAL_SERVICES_PDF_URL =
  'https://farewill-app.s3.eu-west-1.amazonaws.com/site/resources/additional-services.pdf'

export const SOLVENCY_REPORT_PDF_URL =
  'https://farewill-app.s3.eu-west-1.amazonaws.com/site/resources/farewill-funerals-solvency-assessment-of-trust-2024.pdf'

export const FAREWILL_OFFICE_ADDRESS =
  '1st Floor, 27 Downham Road, London N1 5AA'

export const CALLRAIL_FORWARDING_TELEPHONE_NUMBER = '02036952090'

export const { GATSBY_GOOGLE_MAPS_API_KEY } = process.env

export const BURIAL_WITH_A_SERVICE_BROCHURE_URL =
  'https://farewill-app.s3.eu-west-1.amazonaws.com/site/resources/farewill-services-prices-burial-with-service.pdf'

export const DEALING_WITH_DEATH_CHECKLIST_URL =
  'https://farewill-app.s3.eu-west-1.amazonaws.com/site/resources/farewill-dealing-with-death-checklist.pdf'

//------------------------------------------------------------------------------
// Feature Flags
//------------------------------------------------------------------------------
export const CALLBACK_BOOKING_FEATURE_ENABLED =
  process.env.GATSBY_CALLBACK_BOOKING_FEATURE_ENABLED === 'true'

export const FF_EXPT_CTA_FUNERAL_PLANS_LP_ENABLED =
  process.env.GATSBY_FF_EXPT_CTA_FUNERAL_PLANS_LP_ENABLED === 'true'

export const FF_EXPT_COST_COMPARE_FUNERAL_PLANS_LP_ENABLED =
  process.env.GATSBY_FF_EXPT_COST_COMPARE_FUNERAL_PLANS_LP_ENABLED === 'true'

export const FF_EXPT_RECOMMEND_WILL_ENABLED =
  process.env.GATSBY_FF_EXPT_RECOMMEND_WILL_ENABLED === 'true'

export const FF_FUNERAL_PLANS_TV_AD_ENABLED = JSON.parse(
  process.env.GATSBY_FF_FUNERAL_PLANS_TV_AD_ENABLED ||
    appConfig.env.GATSBY_FF_FUNERAL_PLANS_TV_AD_ENABLED.value
)

export const FF_EXPT_PROBATE_QUOTE_TYPE_ENABLED = JSON.parse(
  process.env.GATSBY_FF_EXPT_PROBATE_QUOTE_TYPE_ENABLED ||
    appConfig.env.GATSBY_FF_EXPT_PROBATE_QUOTE_TYPE_ENABLED.value
)

export const NOTICE_BANNER_ENABLED =
  process.env.GATSBY_NOTICE_BANNER_ENABLED === 'true'

export const GATSBY_CROWDCUBE_VIDEO_ENABLED = JSON.parse(
  process.env.GATSBY_CROWDCUBE_VIDEO_ENABLED ||
    appConfig.env.GATSBY_CROWDCUBE_VIDEO_ENABLED.value
)

export const COOKIE_BANNER_ENABLED =
  process.env.GATSBY_COOKIE_BANNER_ENABLED === 'true'

export const FF_TELEPHONE_WILL_ADDITIONAL_COMPLEXITY_ENABLED =
  process.env.GATSBY_FF_TELEPHONE_WILL_ADDITIONAL_COMPLEXITY_ENABLED === 'true'

export const ENABLE_SPLIT_DEBUG_MODE = JSON.parse(
  process.env.GATSBY_ENABLE_SPLIT_DEBUG_MODE ||
    appConfig.env.GATSBY_ENABLE_SPLIT_DEBUG_MODE.value
)
