import { getAllCookies, getCookie } from 'lib/storage/cookies/helpers'
import { TRACKING_PARAMETERS } from './constants'

const toCamelCase = (string: string): string =>
  string
    .toLowerCase()
    .replace(/([_][a-z])/g, (group) => group.toUpperCase().replace('_', ''))

export const formatParameters = (
  parameters: Record<string, unknown>
): Record<string, string> =>
  Object.keys(parameters).reduce<Record<string, string>>(
    (formattedParameters, key) => {
      let formattedKey = key

      if (key === 'gclid') formattedKey = 'googleClickId'
      if (key.includes('_')) formattedKey = toCamelCase(key)

      const parameter = parameters[key]
      if (
        TRACKING_PARAMETERS.includes(formattedKey) &&
        typeof parameter === 'string'
      ) {
        // eslint-disable-next-line no-param-reassign
        formattedParameters[formattedKey] = parameter.trim()
      }
      return formattedParameters
    },
    {}
  )

export const getSnowplowId = (): string | undefined => {
  const snowplowCookieRegex = /spid.\w{4}/
  const allCookies = getAllCookies()

  if (allCookies) {
    const snowplowCookieName = Object.keys(allCookies).filter((key) =>
      key.match(snowplowCookieRegex)
    )[0]

    if (snowplowCookieName) {
      return getCookie(snowplowCookieName)
    }
  }

  return undefined
}

export const getOptimiseExp = (): string | undefined => {
  const cookieName = '_gaexp'

  return getCookie(cookieName)
}

export const getSnowplowIdAsync = async (): Promise<string | undefined> =>
  new Promise((resolve, reject) => {
    const snowplowId = getSnowplowId()

    if (!snowplowId) reject()

    resolve(snowplowId)
  })

export const getOptimiseExpAsync = async (): Promise<string | undefined> =>
  new Promise((resolve, reject) => {
    const optimiseExp = getOptimiseExp()

    if (!optimiseExp) reject()

    resolve(optimiseExp)
  })

export const isInternalReferral = (referrer: string): boolean =>
  referrer.includes('farewill.com')
